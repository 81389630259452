import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { lazy } from "react";

import Loadable from "../components/Loadable";
import Header from "../pages/header";

//Auth Imports
const Login = Loadable(lazy(() => import("../pages/auth/Login")));
const Register = Loadable(lazy(() => import("../pages/auth/Register")));
const Home = Loadable(lazy(() => import("../pages/home/index")));
const Select = Loadable(lazy(() => import("../pages/plans/select")));
const Form = Loadable(lazy(() => import("../pages/plans/Form")));
const Status = Loadable(lazy(() => import("../pages/status/index")));
const Install = Loadable(lazy(() => import("../pages/install/index")));
const Status2 = Loadable(lazy(() => import("../pages/status/NewStatus")));

export default function ThemeRoutes() {
  const isAuth = useSelector((state) => state.auth.isAuth);

  const LoginRoutes = [
    { path: "/login", element: isAuth ? <Navigate to="/home" /> : <Login /> },
  ];

  const mainRoutes = {
    path: "/",
    element: isAuth ? <Header /> : <Navigate to="/login" />,
    children: [
      {
        path: "home",
        element: <Home />,
      },
      {
        path: "select-plans",
        element: <Select />,
      },
      {
        path: "kyc",
        element: <Form />,
      },
    ],
  };

  const statusRoutes = {
    path: "status/:orderId",
    element: (
      <>
        <Header />
        <Status />
      </>
    ),
  };

  const installRoute = { path: "/install", element: <Install /> };

  const newStatus = { path: "/status-new/:status", element: <Status2 /> };

  return useRoutes([
    ...LoginRoutes,
    mainRoutes,
    installRoute,
    newStatus,
    statusRoutes,
  ]);
}
