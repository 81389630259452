import { createSlice } from "@reduxjs/toolkit";

const initialSlice = {
  isAuth: false,
  token: "",
  phone: "",
  user: {},
  billing: [],
  deposite: [],
  admin: [],
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialSlice,
  reducers: {
    setAuth(state, action) {
      state.isAuth = true;
      state.token = action.payload.token;
      state.phone = action.payload.phone;
      state.user = action.payload.user;
    },
    revokeAuth(state, action) {
      state.isAuth = false;
      state.token = "";
      state.phone = "";
      state.user = {};
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setBilling(state, action) {
      state.billing = action.payload;
    },
    setDeposite(state, action) {
      state.deposite = action.payload;
    },
    setAdmin(state, action) {
      state.admin = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
