import { Fragment, useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Grid,
  Button,
  Fab,
  Stack,
  Badge,
  CircularProgress,
} from "@mui/material";
// import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Outlet, useLocation } from "react-router-dom";
import { TbDiscount2, TbLogout, TbRefresh, TbUserCircle } from "react-icons/tb";
import logo from "../../assets/logo.png";
import useWindowWide from "../../hooks/useWindowWide";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { authActions } from "../../store/auth";
import { TbMessage } from "react-icons/tb";
import SupportDialog from "./components/Support";
import CouponDialog from "../home/components/BuyCoupons";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import MyProfile from "./components/MyProfile";
import { useGetUserMutation } from "../../store/services/api";
import Refresh from "./components/Refresh";

const MBar = motion(AppBar);

const useStyles = makeStyles((theme) => ({
  customHoverFocus: {
    "&:hover, &.Mui-focusVisible": { backgroundColor: "#4F7942" },
  },
}));

const Header = (props) => {
  const isDesktop = useWindowWide(625);
  const dispatch = useDispatch();
  const location = useLocation();

  const phone = useSelector((state) => state.auth.phone);
  const user = useSelector((state) => state.auth.user);
  const billing = useSelector((state) => state.auth.billing);
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [coups, setCoups] = useState(false);
  const [profile, setProfile] = useState(false);
  const [lo, setLo] = useState(false);

  return (
    <Fragment>
      <Box sx={{ flexGrow: 1 }}>
        <MBar
          position="fixed"
          sx={{ backgroundColor: "#ffffff" }}
          initial={{ opacity: 0, y: "-25px" }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.7, type: "spring" }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 1.5,
            }}
          >
            <div sx={{ width: "100%", height: "100%" }}>
              <img src={logo} style={{ maxWidth: 160, maxHeight: 40 }} />
            </div>
            <Stack direction={"row"} gap="10px" alignItems={"center"}>
              <Refresh url={location.pathname} />
              {user !== undefined &&
                user !== null &&
                Object.keys(user).length > 0 && (
                  <IconButton
                    aria-label="Delete"
                    // sx={{ color: "#fff" }}
                    color="error"
                    onClick={() => setProfile(true)}
                    sx={{ color: "#4F7942" }}
                  >
                    <Badge color="primary">
                      <TbUserCircle size={25} />
                    </Badge>
                  </IconButton>
                )}
              {user !== undefined &&
                user !== null &&
                Object.keys(user).length > 0 && (
                  <IconButton
                    // className={classes.customHoverFocus}
                    aria-label="Delete"
                    // sx={{ color: "#fff" }}
                    color="error"
                    onClick={() => setCoups(true)}
                    sx={{ color: "#4F7942" }}
                  >
                    <Badge badgeContent={user?.coupons?.length} color="primary">
                      {" "}
                      <TbDiscount2 size={25} />
                    </Badge>
                  </IconButton>
                )}
              <IconButton
                // className={classes.customHoverFocus}
                aria-label="Delete"
                // sx={{ color: "#fff" }}
                color="error"
                onClick={() => setOpen(!open)}
                sx={{ color: "#4F7942" }}
              >
                <Badge color="primary">
                  {" "}
                  <TbMessage size={"25px"} />
                </Badge>
              </IconButton>
              {isDesktop ? (
                <Button
                  startIcon={<TbLogout />}
                  color="error"
                  variant="contained"
                  onClick={() => setLo(true)}
                  sx={{ backgroundColor: "#83C050" }}
                >
                  LOGOUT
                </Button>
              ) : (
                <IconButton
                  className={classes.customHoverFocus}
                  aria-label="Delete"
                  sx={{ backgroundColor: "#83C050", color: "#fff" }}
                  onClick={() => setLo(true)}
                >
                  <TbLogout />
                </IconButton>
              )}
            </Stack>
          </Grid>
        </MBar>
      </Box>
      <Outlet />
      {/* <Fab
        color="success"
        sx={{ position: "absolute", bottom: "25px", right: "25px" }}
        onClick={() => setOpen(!open)}
      >
        <TbMessage size="20px" />
      </Fab> */}
      <SupportDialog open={open} onClose={() => setOpen(false)} />
      <CouponDialog open={coups} onClose={() => setCoups(false)} />
      <MyProfile
        open={profile}
        onClose={() => setProfile(false)}
        user={user}
        billing={billing}
      />
      <LogoutDialog open={lo} handleClose={() => setLo(false)} />
    </Fragment>
  );
};

export default Header;

const LogoutDialog = (props) => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(authActions.revokeAuth());
    localStorage.removeItem("token");
    localStorage.removeItem("phone");
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure you want to logout?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.handleClose}>Disagree</Button>
        <Button onClick={logout} autoFocus>
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};
