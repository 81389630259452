import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const apis = createApi({
  reducerPath: "apis",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://green-a-user-web-zeta.vercel.app/",
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: ["configs", "plans", "coupons"],
  endpoints: (builder) => ({
    getTest: builder.query({
      query: () => "test",
    }),
    getConfigs: builder.query({
      query: () => "user/get-configs",
      providesTags: ["configs"],
    }),
    getPlans: builder.query({
      query: () => "plan/get-plans",
      providesTags: ["plans"],
    }),
    getCoupons: builder.query({
      query: () => "user/get-coupons",
    }),
    registerPlan: builder.mutation({
      query: (formData) => ({
        url: "plan/register",
        method: "POST",
        body: formData,
      }),
    }),
    newPlan: builder.mutation({
      query: (formData) => ({
        url: "plan/new-plan",
        method: "POST",
        body: formData,
      }),
    }),
    getUser: builder.mutation({
      query: (data) => ({
        url: "user/get-user",
        method: "POST",
        body: data,
      }),
    }),
    makeDeposite: builder.mutation({
      query: (data) => ({
        url: "plan/make-deposite",
        method: "POST",
        body: data,
      }),
    }),
    purchaseCouponOnce: builder.mutation({
      query: (data) => ({
        url: "plan/purchase-onetime-coupon",
        method: "POST",
        body: data,
      }),
    }),
    purchaseCouponFree: builder.mutation({
      query: (data) => ({
        url: "plan/purchase-free-coupon",
        method: "POST",
        body: data,
      }),
    }),
    purchaseCouponMonthlyPaid: builder.mutation({
      query: (data) => ({
        url: "plan/purchase-monthly-paid",
        method: "POST",
        body: data,
      }),
    }),
    purchaseCouponMonthlyUnpaid: builder.mutation({
      query: (data) => ({
        url: "plan/purchase-monthly-unpaid",
        method: "POST",
        body: data,
      }),
    }),
    payBill: builder.mutation({
      query: (data) => ({
        url: "plan/pay-bill",
        method: "POST",
        body: data,
      }),
    }),
    addSupportQuery: builder.mutation({
      query: (data) => ({
        url: "user/add-support-query",
        method: "POST",
        body: data,
      }),
    }),
    notifyDeposite: builder.mutation({
      query: (data) => ({
        url: "plan/notify",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetTestQuery,
  useGetPlansQuery,
  useRegisterPlanMutation,
  useGetUserMutation,
  useMakeDepositeMutation,
  useAddSupportQueryMutation,
  useNotifyDepositeMutation,
  usePayBillMutation,
  useGetCouponsQuery,
  usePurchaseCouponFreeMutation,
  usePurchaseCouponOnceMutation,
  usePurchaseCouponMonthlyPaidMutation,
  usePurchaseCouponMonthlyUnpaidMutation,
  useNewPlanMutation,
  useGetConfigsQuery,
} = apis;
