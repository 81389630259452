import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Stack,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import useInput from "../../../hooks/useInput";
import styles from "../../plans/index.module.css";
import { TbArrowBack, TbDownload } from "react-icons/tb";
import { Link } from "react-router-dom";

const MyProfile = (props) => {
  const [bills, setBills] = useState([]);

  useEffect(() => {
    if (props?.billing?.length > 0) {
      const newData = props.billing?.filter((doc) => {
        return doc.isPaid === true;
      });

      setBills(newData);
    }
  }, [props.billing]);

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth="sm"
      fullWidth="sm"
    >
      <DialogTitle>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography variant="h4">My Profile</Typography>
          <IconButton color="error" onClick={() => props.onClose()}>
            <TbArrowBack />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5">Details</Typography>

        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
          sx={{ mt: 1 }}
        >
          <Typography>Name</Typography>
          <Typography>{props?.user?.name}</Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Email</Typography>
          <Typography>{props?.user?.email}</Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Phone</Typography>
          <Typography>{props?.user?.phone}</Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Address</Typography>
          <Typography sx={{ maxWidth: "60%", textAlign: "right" }}>
            {props?.user?.address}
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Registeration</Typography>
          <Typography>
            {new Date(
              props?.user?.regDate?._seconds * 1000 +
                props?.user?.regDate?._nanoseconds / 1000000
            ).toLocaleDateString()}
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Approval</Typography>
          <Typography>
            {new Date(
              props?.user?.approvalDate?._seconds * 1000 +
                props?.user?.approvalDate?._nanoseconds / 1000000
            ).toLocaleDateString()}
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Plan</Typography>
          <Typography sx={{ maxWidth: "60%", textAlign: "right" }}>
            {props?.user?.plan?.name}
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Plan Duration</Typography>
          <Typography sx={{ maxWidth: "60%", textAlign: "right" }}>
            {props.user?.plan?.type === "accomodate"
              ? `${props.user?.plan?.month} Months + ${props.user?.plan
                  ?.free} Months FREE`
              : `${props.user?.plan?.month} Months`}
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Rent</Typography>
          <Typography sx={{ maxWidth: "60%", textAlign: "right" }}>
            &#8377;&nbsp; {props?.user?.plan?.perMonth}&nbsp;/&nbsp;Month
          </Typography>
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction={"row"}
          justifyContent="space-between"
          alignItems={"center"}
        >
          <Typography>Plan Deposite</Typography>
          <Typography sx={{ maxWidth: "60%", textAlign: "right" }}>
            &#8377;&nbsp; {props?.user?.plan?.deposite}
          </Typography>
        </Stack>

        <Typography variant="h5" sx={{ mt: 2 }}>
          Documents
        </Typography>
        <Stack
          direction={"row"}
          //   justifyContent="space-between"
          alignItems={"center"}
          gap="5px"
          flexWrap={"wrap"}
          sx={{ mt: 1 }}
        >
          <Link
            to={props?.user?.adharUrl}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              sx={{
                width: "auto",
                border: "2px solid #83C050",
                borderRadius: "5px",
              }}
              color="primary"
            >
              <TbDownload />
              &nbsp;Adhaar
            </IconButton>
          </Link>
          <Link
            to={props?.user?.adharBackUrl}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              sx={{
                width: "auto",
                border: "2px solid #83C050",
                borderRadius: "5px",
              }}
              color="primary"
            >
              <TbDownload />
              &nbsp;Adhaar (Back)
            </IconButton>
          </Link>
          <Link
            to={props?.user?.lightUrl}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              sx={{
                width: "auto",
                border: "2px solid #83C050",
                borderRadius: "5px",
              }}
              color="primary"
            >
              <TbDownload />
              &nbsp;Electric
            </IconButton>
          </Link>
          <Link
            to={props?.user?.rentUrl}
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <IconButton
              sx={{
                width: "auto",
                border: "2px solid #83C050",
                borderRadius: "5px",
              }}
              color="primary"
            >
              <TbDownload />
              &nbsp;Rent
            </IconButton>
          </Link>
        </Stack>
        <Typography variant="h5" sx={{ mt: 2 }}>
          Payments
        </Typography>
        <Stack
          // direction={"row"}
          // gap="5px"
          // flexWrap={"wrap"}
          // alignItems={"center"}
          className={styles.cards}
          sx={{ mt: 1, maxHeight: "400px", overflow: "auto" }}
        >
          {bills?.length > 0 &&
            bills
              ?.filter((doc) => {
                return doc?.isPaid;
              })
              ?.map((doc, i) => {
                return <ProfileCard doc={doc} key={i} />;
              })}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default MyProfile;

const ProfileCard = ({ doc }) => {
  return (
    <Paper
      sx={{
        p: "10px",
        width: "100%",
        maxWidth: "300px",
        boxShadow: 0,
        border: 1,
        borderColor: "GrayText",
        flexGrow: 1,
      }}
    >
      <Typography variant="h5">
        {" "}
        {new Date(
          doc?.date?._seconds * 1000 + doc?.date?._nanoseconds / 1000000
        ).toLocaleDateString()}
      </Typography>
      <Divider sx={{ my: 0.5, borderColor: "GrayText" }} />
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
      >
        <Typography sx={{ fontWeight: 500 }}>Amount</Typography>
        <Typography
          sx={{ maxWidth: "60%", textAlign: "right", fontWeight: 600 }}
        >
          &#8377;&nbsp;{doc.amount}
        </Typography>
      </Stack>
    </Paper>
  );
};
