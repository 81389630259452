import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Stack,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  IconButton,
  Chip,
} from "@mui/material";
import { TbArrowBack } from "react-icons/tb";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import useInput from "../../../hooks/useInput";
import styles from "../../plans/index.module.css";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  useGetCouponsQuery,
  useNotifyDepositeMutation,
  usePurchaseCouponFreeMutation,
  usePurchaseCouponOnceMutation,
  useGetUserMutation,
  usePurchaseCouponMonthlyUnpaidMutation,
  usePurchaseCouponMonthlyPaidMutation,
} from "../../../store/services/api";
import { authActions } from "../../../store/auth";
import { useDispatch } from "react-redux";

const MPaper = motion(Paper);

const config = {
  root: "",
  style: {
    bodyBackgroundColor: "#fafafb",
    bodyColor: "",
    themeBackgroundColor: "#0FB8C9",
    themeColor: "#ffffff",
    headerBackgroundColor: "#284055",
    headerColor: "#ffffff",
    errorColor: "",
    successColor: "",
    card: {
      padding: "",
      backgroundColor: "",
    },
  },
  payMode: {
    labels: {},
    filter: {
      exclude: [],
    },
    order: ["CC", "DC", "NB", "UPI", "PPBL", "PPI", "BALANCE"],
  },
};

const CouponDialog = (props) => {
  const phone = useSelector((state) => state.auth.phone);
  const user = useSelector((state) => state.auth.user);
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    isError: false,
    msg: "An error occured!",
  });

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="xs"
        fullWidth="xs"
      >
        <DialogTitle sx={{ mb: 2 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Purchase Coupons</Typography>
            <IconButton color="error" onClick={() => props.onClose()}>
              <TbArrowBack size="20px" />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent>
          {user !== undefined &&
          user !== null &&
          Object.keys(user).length > 0 ? (
            user?.coupons?.length > 0 ? (
              user?.coupons?.map((doc, i) => {
                return (
                  <CouponCard
                    key={i}
                    doc={doc}
                    handleClose={props.onClose}
                    user={user}
                  />
                );
              })
            ) : user?.usedCoupons?.length > 0 ? (
              <Alert variant="outlined" color="success">
                <Typography variant="button">
                  Congratulations! Your offer has been successfully redeemed.
                  Enjoy your discount/deal!
                </Typography>
              </Alert>
            ) : (
              <Alert variant="outlined" color="info">
                <Typography variant="button">
                  Sorry, there are currently no offers for you!
                </Typography>
              </Alert>
            )
          ) : (
            <Alert color="info">
              Sorry, there are currently no offers for you!
            </Alert>
          )}
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default CouponDialog;

const CouponCard = (props) => {
  const phone = useSelector((state) => state.auth.phone);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    isError: false,
    msg: "An error occured!",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [payOnce] = usePurchaseCouponOnceMutation();
  const [free] = usePurchaseCouponFreeMutation();
  const [handleMonthlyU] = usePurchaseCouponMonthlyUnpaidMutation();
  const [handleMonthlyP] = usePurchaseCouponMonthlyPaidMutation();
  const [notify] = useNotifyDepositeMutation();
  const [getUser] = useGetUserMutation();

  const handlePayOnce = () => {
    setLoading(true);

    payOnce({ phone: phone, couponId: props.doc.id })
      .unwrap()
      .then((data) => {
        if (!data.success) {
          setError({
            isError: true,
            msg: "Something went wrong!",
          });
          return setLoading(false);
        }

        window.location.href = data?.payload?.token;
      })
      .catch((err) => {
        console.log(err);
        setError({
          isError: true,
          msg: "Something went wrong!",
        });
        return setLoading(false);
      });
  };

  const handleMonthlyPaid = () => {
    setLoading(true);

    handleMonthlyP({ phone: phone, couponId: props.doc.id })
      .unwrap()
      .then((data) => {
        if (!data.success) {
          setError({
            isError: true,
            msg: "Something went wrong!",
          });
          return setLoading(false);
        }

        window.location.href = data?.payload?.token;
      })
      .catch((err) => {
        console.log(err);
        setError({
          isError: true,
          msg: "Something went wrong!",
        });
        return setLoading(false);
      });
  };

  const handleFree = () => {
    setLoading(true);
    free({ phone: phone, couponId: props.doc.id })
      .unwrap()
      .then((data) => {
        if (!data.success) {
          setError({
            isError: true,
            msg: "Something went wrong!",
          });
          return setLoading(false);
        }

        getUser({ phone: phone })
          .unwrap()
          .then((data) => {
            if (data.error) {
              return;
            }

            dispatch(authActions.setUser(data.payload));
            dispatch(authActions.setBilling(data.billing));
            dispatch(authActions.setDeposite(data.depData));
            dispatch(authActions.setAdmin(data.admin));
          });

        setSuccess(true);
        setLoading(false);
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
        setError({
          isError: true,
          msg: "Something went wrong!",
        });
        return setLoading(false);
      });
  };

  const handleMonthlyUnpaid = () => {
    setLoading(true);
    handleMonthlyU({ phone: phone, couponId: props.doc.id })
      .unwrap()
      .then((data) => {
        if (!data.success) {
          setError({
            isError: true,
            msg: "Something went wrong!",
          });
          return setLoading(false);
        }

        getUser({ phone: phone })
          .unwrap()
          .then((data) => {
            if (data.error) {
              return;
            }

            dispatch(authActions.setUser(data.payload));
            dispatch(authActions.setBilling(data.billing));
            dispatch(authActions.setDeposite(data.depData));
            dispatch(authActions.setAdmin(data.admin));
          });

        setSuccess(true);
        setLoading(false);
        props.handleClose();
      })
      .catch((err) => {
        console.log(err);
        setError({
          isError: true,
          msg: "Something went wrong!",
        });
        return setLoading(false);
      });
  };

  return (
    <Fragment>
      <MPaper
        sx={{ p: "15px", mb: 1, cursor: "pointer" }}
        whileHover={{ scale: 1.02 }}
      >
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography variant="h6">{props.doc?.name}</Typography>
          <Button
            color="success"
            size="small"
            variant="contained"
            onClick={() =>
              props.doc?.type === "free"
                ? handleFree()
                : props.doc.type === "monthly"
                ? new Date(
                    props.user?.plan?.endDate?._seconds * 1000 +
                      props.user?.plan?.endDate?._nanoseconds / 1000000
                  ) > new Date()
                  ? handleMonthlyUnpaid()
                  : handleMonthlyPaid()
                : handlePayOnce()
            }
            disabled={loading}
          >
            {loading ? <CircularProgress size={20} /> : " Purchase"}
          </Button>
        </Stack>
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
          sx={{ mt: 2 }}
        >
          <Typography>Price</Typography>
          {props.doc?.type === "free" ? (
            <Typography variant="h6">FREE</Typography>
          ) : props.doc?.type === "monthly" ? (
            <Typography>
              &#8377;&nbsp;{props.doc?.amount}&nbsp;/&nbsp;Month
            </Typography>
          ) : (
            <Typography>&#8377;&nbsp;{props.doc?.amount}</Typography>
          )}
        </Stack>
        <Divider sx={{ my: 0.5 }} />
        <Stack
          direction="row"
          justifyContent={"space-between"}
          alignItems="center"
        >
          <Typography>Validity</Typography>
          <Typography variant="h6">{props.doc?.month}&nbsp;Months</Typography>
        </Stack>
      </MPaper>
      <Snackbar
        open={success}
        onClose={() => setSuccess(false)}
        key="snackbar"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert variant="filled" severity="success">
          Operation Successfull!
        </Alert>
      </Snackbar>
      <Snackbar
        open={error.isError}
        onClose={() =>
          setError({
            isError: false,
            msg: "",
          })
        }
        key="snackbar2"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert variant="filled" severity="error">
          An error occured!
        </Alert>
      </Snackbar>
    </Fragment>
  );
};
