import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  Stack,
  Box,
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
  Snackbar,
  Alert,
  Paper,
  ClickAwayListener,
} from "@mui/material";
import { useSelector } from "react-redux";
import { Fragment, useState } from "react";
import useInput from "../../../hooks/useInput";
import styles from "../../plans/index.module.css";
import { useAddSupportQueryMutation } from "../../../store/services/api";
import { TbCheck } from "react-icons/tb";
import { AnimatePresence, motion } from "framer-motion";

const MPaper = motion(Paper);

const SupportDialog = (props) => {
  const phone = useSelector((state) => state.auth.phone);
  const [issue, setIssue] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    isError: false,
    msg: "An error occured!",
  });

  const [addSupport] = useAddSupportQueryMutation();

  const {
    inputValue: nameValue,
    error: nameError,
    isValid: nameValid,
    inputHandler: nameHandler,
    blurHandler: nameBlur,
    clearInput: nameClear,
  } = useInput((value) => value !== "", "");

  const {
    inputValue: msgValue,
    error: msgError,
    isValid: msgValid,
    inputHandler: msgHandler,
    blurHandler: msgBlur,
    clearInput: msgClear,
  } = useInput((value) => value !== "", "");

  const supportHandler = () => {
    setLoading(true);
    addSupport({
      name: nameValue,
      phone: phone,
      issue: issue,
      message: msgValue,
    })
      .unwrap()
      .then((data) => {
        if (!data.success) {
          setError({
            isError: true,
            msg: "Something went wrong!",
          });
          return setLoading(false);
        }

        nameClear();
        msgClear();
        setIssue("");
        setSuccess(true);
        setLoading(false);
        props.onClose();
      })
      .catch((err) => {
        console.log(err);
        setError({
          isError: true,
          msg: "Something went wrong!",
        });
        return setLoading(false);
      });
  };

  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        maxWidth="md"
        fullWidth="md"
      >
        <DialogTitle>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">GreenAir Support</Typography>
            <Button
              variant="contained"
              color="success"
              onClick={supportHandler}
              disabled={
                !loading && nameValid && msgValid && issue !== "" ? false : true
              }
            >
              {loading ? (
                <CircularProgress size={25} color="inherit" />
              ) : (
                "Send"
              )}
            </Button>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack className={styles.cards}>
            <Box sx={{ width: "100%" }}>
              <InputLabel sx={{ ml: 1, mb: 1 }}>FULL NAME</InputLabel>
              <OutlinedInput
                placeholder="FULL NAME"
                fullWidth
                value={nameValue}
                onChange={nameHandler}
                onBlur={nameBlur}
                size="medium"
              />
              {nameError && (
                <FormHelperText sx={{ ml: 1, color: "red", mt: 1 }}>
                  This is incorrect!
                </FormHelperText>
              )}
            </Box>
            <Box sx={{ width: "100%" }}>
              <InputLabel sx={{ ml: 1, mb: 1 }}>SELECT YOUR ISSUE</InputLabel>
              <Select
                value={issue}
                onChange={(e) => setIssue(e.target.value)}
                fullWidth
                size="medium"
              >
                <MenuItem value="" disabled>
                  Select an issue
                </MenuItem>
                <MenuItem value="Choose Services">Choose Services</MenuItem>
                <MenuItem value="Service Request">Service Request</MenuItem>
                <MenuItem value="Water Leackage">Water Leackage</MenuItem>
                <MenuItem value="AC not Cooling">AC not Cooling</MenuItem>
                <MenuItem value="AC not Working">AC not Working</MenuItem>
              </Select>
            </Box>
          </Stack>
          <Divider sx={{ mt: 2, mb: 1.5 }} />
          <Box sx={{ width: "100%" }}>
            <InputLabel sx={{ ml: 1, mb: 1 }}>MESSAGE</InputLabel>
            <TextField
              variant="outlined"
              placeholder="MESSAGE"
              fullWidth
              value={msgValue}
              onChange={msgHandler}
              onBlur={msgBlur}
              size="medium"
              multiline
            />
            {msgError && (
              <FormHelperText sx={{ ml: 1, color: "red", mt: 1 }}>
                This is incorrect!
              </FormHelperText>
            )}
          </Box>
        </DialogContent>
      </Dialog>
      <AnimatePresence mode="wait">
        <ClickAwayListener onClickAway={() => setSuccess(false)}>
          <Snackbar
            open={success}
            onClose={() => setSuccess(false)}
            key="snackbar"
            sx={{ height: "100%" }}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            // autoHideDuration={2000}
          >
            <MPaper
              sx={{
                p: "15px",
                minWidth: "350px",
                boxShadow: 3,
                textAlign: "center",
                backgroundColor: "#4F7942",
              }}
              initial={{ opacity: 0, scale: 0.7 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.7 }}
            >
              <Stack
                justifyContent={"center"}
                sx={{ width: "100%" }}
                direction="row"
              >
                <TbCheck size="50px" color="#fff" />
              </Stack>
              <Typography
                variant="h4"
                sx={{ mt: 1, fontWeight: 700 }}
                color="#fff"
              >
                Thank you for reaching out to our support team.
              </Typography>
              <Typography
                variant="h6"
                color="#fff"
                sx={{
                  mt: 2,
                  padding: "10px",
                  border: 1,
                  borderColor: "#fff",
                  borderRadius: "5px",
                }}
              >
                We are committed to providing the highest level of customer
                service possible.
              </Typography>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                sx={{ mt: 1 }}
              >
                <Button
                  variant="contained"
                  color="error"
                  sx={{ mt: 2, width: "100%" }}
                  onClick={() => setSuccess(false)}
                >
                  Close
                </Button>
              </Stack>
            </MPaper>
          </Snackbar>
        </ClickAwayListener>
      </AnimatePresence>
      <Snackbar
        open={error.isError}
        onClose={() => setError({ isError: false, msg: "An error occured!" })}
        key="snackbar2"
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert variant="filled" severity="error">
          {error.msg}
        </Alert>
      </Snackbar>
    </Fragment>
  );
};

export default SupportDialog;
