import { combineReducers } from "redux";
import { apis } from "./services/api";
import auth from "./auth";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  auth: auth,
  [apis.reducerPath]: apis.reducer,
});

export default reducer;
