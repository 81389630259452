import React from "react";
import ReactDOM from "react-dom/client";
import "./firebase";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./store";
import CacheBuster from "react-cache-buster";
import LoadingOverlay from "react-loading-overlay";

const currentVersion = "0.3.6";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CacheBuster
    currentVersion={currentVersion}
    isEnabled={true}
    isVerboseMode={false}
    loadingComponent={<LoadingOverlay active={true} spinner />}
  >
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>{" "}
  </CacheBuster>
);

serviceWorkerRegistration.register("serviceWorker.js", {
  registrationStrategy: "registerImmediately",
});
if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/firebase-messaging-sw.js") // Update the path to your service worker file
      .then((registration) => {
        console.log(
          "[SW]: Registered successfully. Scope: ",
          registration.scope
        );
      })
      .catch((error) => {
        console.log("[SW]: Registration failed. Error: ", error);
      });
  });
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
