import { configureStore } from "@reduxjs/toolkit";
import reducer from "./reducer";
import { apis } from "./services/api";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apis.middleware),
});
const persister = "Free";

const { dispatch } = store;

export { store, persister, dispatch };
