import "./App.css";
import { useEffect } from "react";
import Routes from "./routes";
import ThemeCustomization from "./themes";
import ScrollTop from "./components/ScrollTop";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./store/auth";
import { apis } from "./store/services/api";
import { useGetUserMutation } from "./store/services/api";
import {
  database,
  onMessageListener,
  requestFirebaseNotificationPermission,
} from "./firebase";

function App() {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const phone = useSelector((state) => state.auth.phone);
  const [getUser] = useGetUserMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isAuth) {
      console.log("0.3.6");
      const token = localStorage.getItem("token") || "";
      const phone = localStorage.getItem("phone") || "";
      console.log(token);

      if (token !== "" && phone !== "") {
        console.log("here");
        dispatch(authActions.setAuth({ token: token, phone: phone }));
      }
    }
  }, []);

  useEffect(() => {
    const message = localStorage.getItem("message");
    if (isAuth) {
      console.log("here");
      requestFirebaseNotificationPermission()
        .then((token) => {
          console.log(token);
          return fetch("https://green-a-user-web-zeta.vercel.app/user/register-fcm-key", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: token, phone }),
          });
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    }
  }, [isAuth]);

  useEffect(() => {
    if (isAuth) {
      refreshUser();
    }
  }, [isAuth]);

  useEffect(() => {
    if (!isAuth) {
      return;
    }

    const unsub1 = database
      .collection("usersWeb")
      .where("phone", "==", phone)
      .onSnapshot((snap) => {
        refreshUser();
      });

    return () => unsub1();
  }, [isAuth]);

  useEffect(() => {
    const unsub1 = database.collection("webPlans").onSnapshot((snap) => {
      dispatch(apis.util.invalidateTags(["plans"]));
    });

    return () => unsub1();
  }, []);

  useEffect(() => {
    const unsub1 = database.collection("configsWeb").onSnapshot((snap) => {
      dispatch(apis.util.invalidateTags(["configs"]));
      refreshUser();
    });

    return () => unsub1();
  }, []);

  const refreshUser = () => {
    getUser({ phone: phone })
      .unwrap()
      .then((data) => {
        if (data.error) {
          return;
        }

        dispatch(authActions.setUser(data.payload));
        dispatch(authActions.setBilling(data.billing));
        dispatch(authActions.setDeposite(data.depData));
        dispatch(authActions.setAdmin(data.admin));
      });
  };

  onMessageListener()
    .then((payload) => {
      console.log(payload);
      const { title, message } = payload.data;
      const n1 = new Notification(title, {
        body: message,
      });

      n1.onclick = () => {
        console.log("Notification clicked!");
      };
    })
    .catch((err) => {
      console.log(err);
    });

  return (
    <ThemeCustomization>
      <ScrollTop>
        <Routes />
      </ScrollTop>
    </ThemeCustomization>
  );
}

export default App;
