import { useDispatch } from "react-redux";
import { Badge, Button, CircularProgress, IconButton } from "@mui/material";
import {
  useGetConfigsQuery,
  useGetPlansQuery,
  useGetUserMutation,
} from "../../../store/services/api";
import { TbRefresh } from "react-icons/tb";
import { useSelector } from "react-redux";
import { authActions } from "../../../store/auth";

const Refresh = (props) => {
  const dispatch = useDispatch();
  const phone = useSelector((state) => state.auth.phone);
  const [getUser, { isLoading }] = useGetUserMutation();
  const {
    data: configs,
    isFetching,
    refetch: refetchConfig,
  } = useGetConfigsQuery();
  const {
    data,
    isFetching: isPlanLoading,
    refetch: refetchPlam,
  } = useGetPlansQuery();

  const refreshUser = () => {
    getUser({ phone: phone })
      .unwrap()
      .then((data) => {
        if (data.error) {
          return;
        }

        dispatch(authActions.setUser(data.payload));
        dispatch(authActions.setBilling(data.billing));
        dispatch(authActions.setDeposite(data.depData));
        dispatch(authActions.setAdmin(data.admin));
      });
  };

  return (
    <IconButton
      aria-label="Delete"
      // sx={{ color: "#fff" }}
      color="error"
      disabled={isLoading || isFetching || isPlanLoading}
      onClick={() => {
        props.url === "/home"
          ? refreshUser()
          : props.url === "/select-plans"
          ? refetchPlam()
          : refetchConfig();
      }}
      sx={{ color: "#4F7942" }}
    >
      <Badge color="primary">
        {isLoading || isFetching || isPlanLoading ? (
          <CircularProgress size={20} />
        ) : (
          <TbRefresh size={25} />
        )}
      </Badge>
    </IconButton>
  );
};

export default Refresh;
