// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCVS03Ck4LGFQCAc61VEsRHvdRPBYJ6uew",
  authDomain: "c-g-a-a4280.firebaseapp.com",
  projectId: "c-g-a-a4280",
  storageBucket: "c-g-a-a4280.appspot.com",
  messagingSenderId: "647175328901",
  appId: "1:647175328901:web:e179dcdb21e9eae04585ec",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
var auth = firebase.auth();
export const database = firebase.firestore();
export const messaging = firebase.messaging();
export { auth, firebase };

export const requestFirebaseNotificationPermission = () => {
  return new Promise((resolve, reject) => {
    Notification.requestPermission()
      .then(() => {
        localStorage.setItem("message", "granted");
        console.log("Notification permission granted.");
        return messaging.getToken();
      })
      .then((token) => {
        console.log("Device token:", token);
        resolve(token);
      })
      .catch((error) => {
        console.error("Error requesting permission:", error);
        reject(error);
      });
  });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
